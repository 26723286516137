export default function () {

    const config = {
        'selector': '.lightbox',
        'elementContainerSelector': '.lightbox__elements'
    }

    function createLightbox() {
        if (document.querySelector('.lightbox') != null) {
            return;
        }

        let wrapper = document.createElement('div')
        wrapper.classList.add('lightbox');

        wrapper.innerHTML = `
                <div class="lightbox--container">
                    <div class="lightbox--wrapper">

                        <div class="lightbox__controls d-none">
                            <button id="lightbox__btn--previous" class="lightbox__btn--previous"></button>
                            <button id="lightbox__btn--next" class="lightbox__btn--next"></button>
                        </div>

                        <div class="lightbox__elements">
                        </div>
                    </div>
                </div>`;

        document.querySelector('body').appendChild(wrapper);

        document.getElementById('lightbox__btn--next').addEventListener('click', next);
        document.getElementById('lightbox__btn--previous').addEventListener('click', previous);

        document.querySelector(config.selector).addEventListener('click', closeLightbox);
    }

    createLightbox();

    document.querySelectorAll('[data-toggle="lightbox"]').forEach((element) => {
        element.addEventListener('click', () => { openLightbox(element) });
    });

    function openLightbox(element) {

        resetLightbox();

        let group = element.dataset.lightboxGroup;

        let shownElements =
            group === undefined
                ? document.querySelectorAll(`[data-toggle="lightbox"]`)
                : document.querySelectorAll(`[data-lightbox-group="${group}"]`);

        let addedElements = [];
        let foundIndex = 0;
        shownElements.forEach((shownElement, index) => {

            if (addedElements[shownElement.dataset.lightboxComponent]) {
                return;
            }

            addImageToLightbox(shownElement);

            addedElements[shownElement.dataset.lightboxComponent] = true;

            if (element.dataset.lightboxComponent == shownElement.dataset.lightboxComponent) {
                foundIndex = index;
            }
        });

        document.querySelector(config.elementContainerSelector).style.transform = `translateX(-${100 * foundIndex}%)`;

        if (foundIndex == 0) {
            document.getElementById('lightbox__btn--previous').classList.add('lightbox__btn--disabled');
        }

        if (foundIndex >= document.querySelector(config.elementContainerSelector).childElementCount - 1) {
            document.getElementById('lightbox__btn--next').classList.add('lightbox__btn--disabled');
        }

        document.querySelector(config.selector).classList.add('lightbox--active');

    }

    function closeLightbox(e) {
        if (e.target.id !== 'lightbox__btn--previous' && e.target.id !== 'lightbox__btn--next') {
            document.querySelector(config.selector).classList.remove('lightbox--active');
        }
    }

    function resetLightbox() {
        document.querySelector(config.elementContainerSelector).innerHTML = "";

        document.getElementById('lightbox__btn--previous').classList.remove('lightbox__btn--disabled');
        document.getElementById('lightbox__btn--next').classList.remove('lightbox__btn--disabled');
    }

    function addImageToLightbox(shownElement) {
        let content = document.querySelector(shownElement.dataset.lightboxComponent) != null
            ? document.querySelector(shownElement.dataset.lightboxComponent)
            : shownElement;

        document.querySelector(config.elementContainerSelector).innerHTML +=
            `<div class="lightbox__element--container">
                <div class="lightbox__element">
                    ${content.innerHTML}
                </div>
            </div>`;
    }

    function next(e) {
        let currentPosition = getPosition();
        let element = document.querySelector(config.elementContainerSelector);

        if (Math.abs(currentPosition / 100) >= element.childElementCount - 1) {
            return;
        }

        element.style.transform = `translateX(${currentPosition - 100}%)`;

        document.getElementById('lightbox__btn--previous').classList.remove('lightbox__btn--disabled');
        if (Math.abs((currentPosition - 100) / 100) >= element.childElementCount - 1) {
            e.target.classList.add('lightbox__btn--disabled');
        }
    }

    function previous(e) {
        let currentPosition = getPosition();
        let element = document.querySelector(config.elementContainerSelector);

        if (Math.abs(currentPosition / 100) <= 0) {
            return;
        }

        element.style.transform = `translateX(${currentPosition + 100}%)`;

        document.getElementById('lightbox__btn--next').classList.remove('lightbox__btn--disabled');
        if (Math.abs((currentPosition + 100) / 100) <= 0) {
            e.target.classList.add('lightbox__btn--disabled');
        }
    }

    function getPosition() {

        let transform = document.querySelector(config.elementContainerSelector).style.transform;

        let match = 0;
        if (transform !== '') {
            match = transform.match(/(translateX\()(.*)(?=(%\)))/g)[0]
                .replace('translateX(', '');
        }

        return parseInt(match);
    }

};