import $ from "jquery";

/*============================
 HEADER
 =============================*/
function navScroll(options = {}) {
  let config = {
    ...options,
    ...{
      header: ".header",
      headerSpacer: ".header--spacer",
      shown: true,
      atTop: false,
      previousPosition: 0,

      headerElement: null,
      headerFixedElement: null,

      shownClass: "header--shown",
      topClass: "header--top",
      spacerTopClass: "header--fixed",

      headerOffsetBeforeHiding: 100,
      currentHeaderOffsetBeforeHiding: 0
    }
  };

  function setup() {
    config.headerElement = document.querySelector(config.header);
    config.headerSpacerElement = document.querySelector(config.headerSpacer);

    handleScroll();
    window.addEventListener("scroll", handleScroll);
  }

  setup();

  function handleScroll(e) {
    let scrollPosition = window.pageYOffset;

    //if the mobile menu is open stop it scrolling
    if (config.headerElement.classList.contains("header--mobile-menu--open")) {
      return;
    }

    //currently at the top of the page
    if (scrollPosition == 0 || scrollPosition == -1) {
      if (config.atTop) {
        return;
      }

      if (!config.headerElement.classList.contains(config.shownClass)) {
        config.headerElement.classList.add(config.shownClass);
      }

      config.headerElement.classList.add(config.topClass);
      config.headerSpacerElement.classList.add(config.spacerTopClass);

      config.atTop = true;

      config.previousPosition = scrollPosition;
      return;
    }

    //if the function is still running must not be at the top of the page
    config.atTop = false;
    config.headerElement.classList.remove(config.topClass);
    config.headerSpacerElement.classList.remove(config.spacerTopClass);

    config.currentHeaderOffsetBeforeHiding -= Math.abs(
      config.previousPosition - scrollPosition
    );

    //moving downwards
    if (
      scrollPosition > config.previousPosition &&
      config.currentHeaderOffsetBeforeHiding <= 0
    ) {
      config.headerElement.classList.remove(config.shownClass);

      config.shown = false;
    }
    //moving upwards
    else if (scrollPosition < config.previousPosition) {
      config.currentHeaderOffsetBeforeHiding = config.headerOffsetBeforeHiding;

      if (!config.headerElement.classList.contains(config.shownClass)) {
        config.headerElement.classList.add(config.shownClass);
      }

      config.shown = true;
    }

    config.previousPosition = scrollPosition;
  }
}

navScroll();

/* ========================================================= */
document.querySelectorAll(".navbar-toggler").forEach(element => {
  element.addEventListener("click", () => {
    document.querySelector(".header-nav").classList.toggle("header-nav-show");
    document
      .querySelector(".header")
      .classList.toggle("header--mobile-menu--open");
  });
});

/* SEARCH BAR */
// document.querySelector('.header-search__close').addEventListener('click', function () {
//   document.querySelector('.header-search').classList.remove('active');
// })

// document.querySelector('.header-search__label-init').addEventListener('click', function () {
//   document.querySelector('.header-search').classList.add('active');
// })

const pseudoWidth = 30;
document
  .querySelectorAll('.nav-link[data-toggle="dropdown"]')
  .forEach(element => {
    element.addEventListener("click", e => {
      if (e.layerX < e.target.clientWidth - pseudoWidth) {
        e.stopImmediatePropagation();
      }
    });
  });

/* Add hover dropdown */
$("body").on("mouseenter mouseleave", ".nav-item", function(e) {
  if ($(window).width() > 750) {
    var _d = $(e.target).closest(".nav-item");
    _d.addClass("show");
    setTimeout(function() {
      _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
    }, 1);
  }
});

$(".nav-item.dropdown").click(function() {
  $(this).toggleClass("show");
});

/* ====================================================================== */
/* ====================================================================== */
/* ====================================================================== */

$(".search-button").click(function() {
  $(this).toggleClass("active");
  $(".search-form").toggleClass("search-form-show");
});
