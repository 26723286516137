var f = new Filter({
    container: '#events--container',
    postType: 'events',
    callback: function(container, response){

        /** IE doesn't treat response as an object - parse it as json if not already an object */
        var response = !response.posts ? JSON.parse(response) : response

        response.posts.forEach(function(item, index){
            let html = `<a class="card events__event--card scale--hover arrow--hover" href="${item.guid}" data-aos="fade-up">
                        <div class="card-body events__event--card-body">
                            <div class="events__event--image" style="background: url(${item.image}) no-repeat center/cover;"></div>
                            <div class="events__event--content">
                                ${item.excerpt}
        
                                <span class="mt-3 btn__arrow">${index < 3 ? 'Read more about this event' : item.date}</span>
                            </div>
                        </div>
                    </a>`;

            let element = document.createElement('div');
            element.classList.add('events__event');
            element.innerHTML = html;

            document.querySelector(container).appendChild(element);
        });
    },
    per_page: 12,
    initialRequest: true
});