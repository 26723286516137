export default function(){
    document.querySelectorAll('[data-toggle="images"]')
        .forEach((element) => {

            element.addEventListener('click', () => {

                document.querySelector('.photo-gallery__images').innerHTML = document.querySelector(element.dataset.target).innerHTML;

                document.querySelector('.photo-gallery__images--title').innerHTML = element.dataset.title;

                lightbox();

                document.querySelector('.photo-gallery').style.transform = 'translateX(-100%)';
            })
        });

    if(document.querySelector('.photo-gallery__images--back') != null) {
        document.querySelector('.photo-gallery__images--back')
            .addEventListener('click', () => {
                document.querySelector('.photo-gallery').style.transform = 'translateX(0)';
                window.scrollTo(0, 0);

                //Below will scroll to the top of the photo gallery but is a little buggy with the menu position
                //window.scrollTo(0, document.querySelector('.photo-gallery').getBoundingClientRect().top);
            });
    }
};