import $ from "jquery";
window.$ = window.jQuery = $;

import "bootstrap";
import "popper.js";
import aos from "aos";

import "./modules/navigation";
import "./modules/media-articles";
import "./modules/swiper";
import "./modules/map";

import lightbox from "./modules/lightbox";
window.lightbox = lightbox;

import photoGallery from "./pages/photo-gallery";
window.photoGallery = photoGallery;

import "./pages/powder-coat-colours";
import "./pages/events";

document.addEventListener("DOMContentLoaded", function() {
  photoGallery();
  lightbox();
});

aos.init();

$(document).ready(function() {
  // Gets the video src from the data-src on each button
  var $videoSrc;
  $(".video-btn").click(function() {
    $videoSrc = $(this).data("src");
  });

  // when the modal is opened autoplay it
  $("#video-modal").on("shown.bs.modal", function(e) {
    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
    $("#video").attr(
      "src",
      $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
    );
  });

  // stop playing the youtube video when I close the modal
  $("#video-modal").on("hide.bs.modal", function(e) {
    // a poor man's stop video
    $("#video").attr("src", $videoSrc);
  });

  $("#subscribe-button").click(function() {
    $('#subscribe-modal form input[type="submit"]').trigger("click");
  });

  if ($("#location").length) {
    $("#location");
  }
});

document.getElementById("mobilePhoneToggle").onclick = () => {
  document.getElementById("mobilePhoneModal").classList.toggle("active");
};

document.getElementById("modalBg").onclick = () => {
  document.getElementById("mobilePhoneModal").classList.remove("active");
};

document.getElementById("closePhoneModal").onclick = () => {
  document.getElementById("mobilePhoneModal").classList.remove("active");
};
