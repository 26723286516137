if (document.getElementById("media__featured--posts") != null) {
  var f = new Filter({
    target: null,
    activeTagClass: null,
    container: "#media__featured--posts",
    postType: document.getElementById("media__featured--posts").dataset
      .postType,
    singleTag: true,
    showLoader: false,
    loading: function(container) {
      document.querySelector(container).classList.add("loading-spinner");
    },
    callback: function(container, response) {
      container = document.querySelector(container);

      container.classList.remove("loading-spinner");

      /** IE doesn't treat response as an object - parse it as json if not already an object */
      var response = !response.posts ? JSON.parse(response) : response;

      response.posts.forEach(function(item) {
        let html = `<a class="card my-2 arrow--hover scale--hover no-underline" href="${
          item.guid
        }">
                                  <div class="card-body">
                                      <div class="card-img-container position-relative mb-3">
                                          <div class="card--image mb-2" style="background-image: url(${
                                            item.image
                                          })"></div>
                                      </div>
                                      <div class="card--content-container">
                                          <h3 class="card--title">${
                                            item.post_title
                                          }</h3>
                                          <div class="card--content">${
                                            item.excerpt
                                          }</div>
                                          <div class="btn__arrow mt-1">
                                          ${
                                            container.dataset.postType ==
                                            "blogs"
                                              ? "Read the blog"
                                              : container.dataset.postType ==
                                                "post"
                                              ? "Read the news story"
                                              : "Read the case study"
                                          }
                                          </div>
                                      </div>
                                  </div>
                              </a>`;

        let element = document.createElement("a");
        element.classList.add(
          "media__featured--post",
          "scale--hover-next",
          "arrow--hover",
          "my-2"
        );
        element.attributes.href = item.guid;
        element.innerHTML = html;

        container.appendChild(element);
      });
    },
    formDataCallback: function(data, items) {
      data.append(
        "taxonomy",
        document.getElementById("media__featured--posts").dataset
          .categoryPostType
      );
      data.append(
        "term",
        document.getElementById("media__featured--posts").dataset.category
      );

      return data;
    },
    per_page: 12,
    initialRequest: true,
  });
}
