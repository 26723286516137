var f = new Filter({
    target: '.powder-coat-colours__categories--category',
    activeTagClass: 'powder-coat-colours__categories--category--active',
    container: '#powder-coat-colours-container',
    postType: 'powder_coat_colours',
    defaultTagType: 'powder_coat_colour_categories',
    singleTag: true,
    callback: function(container, response){

        /** IE doesn't treat response as an object - parse it as json if not already an object */
        var response = !response.posts ? JSON.parse(response) : response

        response.posts.forEach(function(item){
            let html = `<div class="card mb-4 powder-coat-colours__card scale--hover arrow--hover cursor-pointer" data-aos="fade-up" data-toggle="lightbox" data-lightbox-component="#powder-coat-colours__lightbox--${item.ID}" data-lightbox-group="powder-coat-colours__lightbox">
                            <div class="card-body">
                                <div class="powder-coat-colours__card--image card--image mb-3" style="background-image: url(${item.image})"></div>
                                <div class="powder-coat-colours__card--content card--content-container">
                                    <h3 class="powder-coat-colours__card--title card--title">${item.post_title}</h3>
                                    
                                    <div class="card--content"></div>
                                    
                                    <span class="powder-coat-colours__card--link btn__arrow">${item.reference_number}</span>
                                    
                                    <div class="d-none" id="powder-coat-colours__lightbox--${item.ID}">
                                        <div class="d-flex text-white powder-coat-colours__lightbox">
                                            <div class="flex-grow-1 d-flex justify-content-center align-items-center powder-coat-colours__lightbox--image-container">
                                                <div class="img-wrapper powder-coat-colours__lightbox--image-wrapper">
                                                    <img class="powder-coat-colours__lightbox--image" src="${item.image}">
                                                </div>
                                            </div>
                                            <div class="powder-coat-colours__lightbox--content bg-dark p-2">
                                                <h3 class="powder-coat-colours__lightbox--title">${item.post_title}</h3>
                                                <h4>Details</h4>
                                                <hr class="bg-white" />
                                                
                                                <div>${item.post_content}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>`;

            let element = document.createElement('div');
            element.classList.add('col-12', 'col-sm-6', 'col-lg-4', 'd-flex');
            element.innerHTML = html;

            document.querySelector(container).appendChild(element);

            lightbox();
        });
    },
    formDataCallback: function( data, items ) {

        if(items['powder_coat_colour_categories'] !== undefined && items['powder_coat_colour_categories'] !== ''){
            data.append('taxonomy', 'powder_coat_colour_categories');
            data.append('term', items['powder_coat_colour_categories']);
            data.append('orderby', 'title');
            data.append('order', 'ASC');
        }

        return data;
    },
    per_page: 12,
    initialRequest: true
});