// const config = [
//   {
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#f5f5f5"
//       }
//     ]
//   },
//   {
//     elementType: "labels",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     elementType: "labels.icon",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#616161"
//       }
//     ]
//   },
//   {
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         color: "#f5f5f5"
//       }
//     ]
//   },
//   {
//     featureType: "administrative",
//     elementType: "geometry",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     featureType: "administrative.land_parcel",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#bdbdbd"
//       }
//     ]
//   },
//   {
//     featureType: "administrative.neighborhood",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     featureType: "poi",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     featureType: "poi",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#eeeeee"
//       }
//     ]
//   },
//   {
//     featureType: "poi",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#757575"
//       }
//     ]
//   },
//   {
//     featureType: "poi.park",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#e5e5e5"
//       }
//     ]
//   },
//   {
//     featureType: "poi.park",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9e9e9e"
//       }
//     ]
//   },
//   {
//     featureType: "road",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     featureType: "road",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#ffffff"
//       }
//     ]
//   },
//   {
//     featureType: "road",
//     elementType: "labels.icon",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     featureType: "road.arterial",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#757575"
//       }
//     ]
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#dadada"
//       }
//     ]
//   },
//   {
//     featureType: "road.highway",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#616161"
//       }
//     ]
//   },
//   {
//     featureType: "road.local",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9e9e9e"
//       }
//     ]
//   },
//   {
//     featureType: "transit",
//     stylers: [
//       {
//         visibility: "off"
//       }
//     ]
//   },
//   {
//     featureType: "transit.line",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#e5e5e5"
//       }
//     ]
//   },
//   {
//     featureType: "transit.station",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#eeeeee"
//       }
//     ]
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#c9c9c9"
//       }
//     ]
//   },
//   {
//     featureType: "water",
//     elementType: "labels.text.fill",
//     stylers: [
//       {
//         color: "#9e9e9e"
//       }
//     ]
//   }
// ];


const config = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]


var markers = [];
var map = null;

if (document.getElementById("map")) {
  initMap();
}

function initMap() {
  const mapElement = document.getElementById("map");
  const center = new google.maps.LatLng(54.5781, -3.0638);

  var infoWindows = [];

  let markerStyle = {
    path: "M0 0 L5 0 L5 5 L0 5 Z", //basic square (22 is the limit for sizing)
    rotation: 45,
    fillColor: "#000000",
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 2
  };

  mapElement.style.height = "500px";

  map = new google.maps.Map(mapElement, {
    zoom: 5.6,
    center: center,
    styles: mapElement.dataset.ignoreMapConfig !== undefined ? {} : config
  });

  document.querySelectorAll("[data-locations]").forEach(element => {
    const locations = JSON.parse(element.dataset.locations)
    
    locations.forEach(location => {

      let marker = new google.maps.Marker({
        position: {
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng),
        },
        map: map,
        title: element.innerHTML,
        icon: {
          ...markerStyle,
          fillColor: element.dataset.backgroundColor,
          ...(element.dataset.iconConfig !== undefined
            ? JSON.parse(element.dataset.iconConfig)
            : {}),
        },
      })

      markers.push(marker)

      if (element.dataset.contentContainer !== undefined) {
        let infoWindow = new google.maps.InfoWindow({
          content: document.querySelector(element.dataset.contentContainer)
            .innerHTML
        });
  
        infoWindows.push(infoWindow);
  
        marker.addListener("click", () => {
          closeWindow();
          infoWindow.open(map, marker);
        });
      }
      
    })
  })

  document.querySelectorAll("[data-location]").forEach(element => {
    let markerCenter = JSON.parse(element.dataset.location);

    let marker = new google.maps.Marker({
      position: {
        lat: parseFloat(markerCenter.lat),
        lng: parseFloat(markerCenter.lng)
      },
      map: map,
      title: element.innerHTML,
      icon: {
        ...markerStyle,
        fillColor: element.dataset.backgroundColor,
        ...(element.dataset.iconConfig !== undefined
          ? JSON.parse(element.dataset.iconConfig)
          : {})
      }
    });

    markers.push(marker);

    if (element.dataset.contentContainer !== undefined) {
      let infoWindow = new google.maps.InfoWindow({
        content: document.querySelector(element.dataset.contentContainer)
          .innerHTML
      });

      infoWindows.push(infoWindow);

      marker.addListener("click", () => {
        closeWindow();
        infoWindow.open(map, marker);
      });
    }
  });

  google.maps.event.addListener(map, "click", () => {
    closeWindow();
  });

  function closeWindow() {
    infoWindows.forEach(element => {
      element.close();
    });
  }

  var markerCluster = new MarkerClusterer(map, markers, {
    gridSize: 30,
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
  });
}
